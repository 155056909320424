import { defuFn } from 'defu'
import { default as rootConfig } from 'drmax/config/pl-drogeria/app.config.mjs'
import { default as i18n } from '@nsf/i18n/config/pl-drogeria/app.config.mjs'
import { default as csp } from '@nsf/csp/config/pl-drogeria/app.config.mjs'
import { default as base } from '@nsf/base/config/pl-drogeria/app.config.mjs'
import { default as algolia } from '@nsf/algolia/config/pl-drogeria/app.config.mjs'
import { default as search } from '@nsf/search/config/pl-drogeria/app.config.mjs'
import { default as articles } from '@nsf/articles/config/pl-drogeria/app.config.mjs'
import { default as apothekaWeb } from '@nsf/apotheka-web/config/pl-drogeria/app.config.mjs'
import { default as pickupSelect } from '@nsf/pickup-select/config/pl-drogeria/app.config.mjs'
import { default as pickupSelectV2 } from '@nsf/pickup-select-v2/config/pl-drogeria/app.config.mjs'
import { default as checkout } from '@nsf/checkout/config/pl-drogeria/app.config.mjs'
import { default as homepage } from '@nsf/homepage/config/app.config.mjs'
import { default as catalog } from '@nsf/catalog/config/pl-drogeria/app.config.mjs'
import { default as speedCurve } from '@nsf/speed-curve/config/pl-drogeria/app.config.mjs'
import { default as gtm } from '@nsf/gtm/config/pl-drogeria/app.config.mjs'
import { default as tags } from '@nsf/tags/config/app.config.mjs'
import { default as deliveryFe } from '@nsf/delivery-fe/config/pl-drogeria/app.config.mjs'
import { default as productRelationshipLoaderPersoo } from '@nsf/product-relationship-loader-persoo/config/pl-drogeria/app.config.mjs'
import { default as productRelationshipLoaderPlacement } from '@nsf/product-relationship-loader-placement/config/pl-drogeria/app.config.mjs'
import { default as cms } from '@nsf/cms/config/pl-drogeria/app.config.mjs'
import { default as clubs } from '@nsf/clubs/config/pl-drogeria/app.config.mjs'
import { default as validations } from '@nsf/validations/config/pl-drogeria/app.config.mjs'
import { default as myAccount } from '@nsf/my-account/config/pl-drogeria/app.config.mjs'

const inlineConfig = {}
export default /* #__PURE__ */ defuFn({rootConfig, i18n, csp, base, algolia, search, articles, apothekaWeb, pickupSelect, pickupSelectV2, checkout, homepage, catalog, speedCurve, gtm, tags, deliveryFe, productRelationshipLoaderPersoo, productRelationshipLoaderPlacement, cms, clubs, validations, myAccount}, inlineConfig)
